import { List, ListItem, Typography } from '@mui/material'
import ApplicationCard from '@norstella/nxp-sso-web/components/ApplicationCard'
import styles from '@norstella/nxp-sso-web/views/EmailCheckFailed.module.scss'
import { useBranding } from '../hooks/useBranding'
import { ReactElement } from 'react'

export const EmailCheckFailed = () => {
  const [forms] = useBranding()
  const headerMeta = forms.emailCheckFailed
  const contactsData = headerMeta.contacts

  const contactsList: ReactElement[] = []
  if (contactsData?.phones) {
    for (const phone of contactsData.phones) {
      const element = <ListItem sx={{ display: 'list-item' }}>
        {phone}
      </ListItem>
      contactsList.push(element)
    }
  }

  if (contactsData?.email) {
    const emailHref = `mailto:${contactsData.email}`
    const element = <ListItem sx={{ display: 'list-item' }}>
      email: <a href={emailHref}>{contactsData.email}</a>
    </ListItem>
    contactsList.push(element)
  }

  if (contactsData?.link) {
    const element = <ListItem sx={{ display: 'list-item' }}>
      <a href={contactsData.link.url} target="_blank" rel="noopener nofollow noreferrer">{contactsData.link.text}</a>
    </ListItem>
    contactsList.push(element)
  }

  return (
    <>
      {
        <ApplicationCard header={headerMeta.header} description={headerMeta.description}>
          <Typography component="p">
            Thank you for signing up. Unfortunately we are unable to process this.
          </Typography>

          {contactsList.length > 0 &&
          <section>
            <Typography component="p">
              Please contact our client services team:
            </Typography>
            <List sx={{
              listStyleType: 'disc',
              listStylePosition: 'inside',
              lineHeight: '4px',
              paddingTop: '1px',
            }} className={styles.contacts}>
              {contactsList}
            </List>
            </section>
          }
        </ApplicationCard>
      }
    </>
  )
}
