import React, { FC, SyntheticEvent } from 'react'

import { Button, Divider, Link, Typography } from '@mui/material'
import { FormType } from '@norstella/nxp-sso-web/utils/Forms'
import { IProfileData, UserType } from '@norstella/nxp-sso-core/interfaces/sso-api'

import { UserProfile } from '@norstella/nxp-sso-web/components/userProfile/UserProfile'
import { TokenTester } from '@norstella/nxp-sso-web/components/TokenTester'

import styles from '@norstella/nxp-sso-web/components/views/ProfilePageView.module.scss'
import { useAppContext } from '@norstella/nxp-sso-web/components/AppContextProvider'
import { AuthorizationGuard } from '@norstella/nxp-sso-web/components/AuthorizationGuard'

export interface IProfilePageViewProps {
  formType: FormType,
  isUpdateInProgress?: boolean,
  submitButtonText: string,
  onSubmitHandler: (profileData: IProfileData) => Promise<void>,
  onNavigateBackHandler?: (event: SyntheticEvent) => void,
  onPasswordChangeHandler?: () => Promise<void>,
  subHeader?: JSX.Element,
}

export const ProfilePageView: FC<IProfilePageViewProps> = ({
  formType,
  onSubmitHandler,
  submitButtonText,
  isUpdateInProgress,
  onNavigateBackHandler,
  subHeader,
  onPasswordChangeHandler,
}) => {
  const { formUpdated, user } = useAppContext()
  const submitDisabled = !formUpdated || isUpdateInProgress

  const onPasswordClick = (event: React.SyntheticEvent) => {
    event.preventDefault()

    if (onPasswordChangeHandler) {
      onPasswordChangeHandler()
    }
  }

  const isProfilePage = [FormType.profile, FormType.trialProfile].includes(formType)
  let isRegularUser

  if (user) {
    isRegularUser = [UserType.user, UserType.trial].includes(user.ut)
  }

  const isChangePasswordAvailable = isProfilePage && isRegularUser

  return (
    <UserProfile
      formType={formType}
      onSubmitHandler={onSubmitHandler}
      subHeader={subHeader}
    >
      <div className={styles.profileViewButtonsRoot}>
        <AuthorizationGuard scopes={['nxp-sso:token-tester']}>
          <TokenTester />
        </AuthorizationGuard>
        <Button
          data-testid="update-button"
          disabled={submitDisabled}
          type="submit"
          variant="contained"
          color="primary">
          {submitButtonText}
        </Button>
        {onNavigateBackHandler &&
          <Button
            data-testid="back-button"
            disabled={false}
            type="button"
            variant="contained"
            color="primary"
            onClick={onNavigateBackHandler}
          >
            back
          </Button>
        }
      </div>

      {isChangePasswordAvailable && <div>
        <Divider></Divider>

        <Typography variant="h2" component="h2">
          Password
        </Typography>

        <Link
          href="#"
          onClick={onPasswordClick}
        >Change password</Link>
      </div>
      }
    </UserProfile>
  )
}
