import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, Typography, Divider } from '@mui/material'
import ApplicationCard from '@norstella/nxp-sso-web/components/ApplicationCard'
import { useAppContext } from '@norstella/nxp-sso-web/components/AppContextProvider'
import { useBranding } from '../hooks/useBranding'

// TODO: refactor to reduce complexity
// eslint-disable-next-line complexity
export const VerifyEmail = () => {
  const {
    user,
    apiHooks,
  } = useAppContext()
  const [forms] = useBranding()
  const [isButtonDirty, setIsButtonDirty] = useState(false)
  const [isButtonVisible, setIsButtonVisible] = useState(true)
  const [searchParams] = useSearchParams()
  const [verifyEmail] = apiHooks.user.useVerifyEmail()
  const headerMetaOnSuccess = forms.verifyEmail
  const headerMetaOnError = forms.verifyEmailError

  const isVerificationFailed = searchParams.get('success') === 'false'
  const isVerificationSuccess = !isVerificationFailed

  const onClickVerify = () => {
    setIsButtonDirty(true)
    verifyEmail()
  }

  let headerMeta = headerMetaOnSuccess

  if (isVerificationFailed) {
    headerMeta = headerMetaOnError
  }

  const message = searchParams.get('message')
  const isLinkExpired = message === 'Access expired.'
  if (isLinkExpired) {
    headerMeta.header.text = 'Link Expired'
  }

  let verificationContentMain
  let verificationContentSecondary

  if (user && isVerificationSuccess) {
    verificationContentMain = <>
      <Typography component="p">
        A verification email has been sent to the email address {user.email}.
      </Typography>

      <Typography component="p">
        Use the verification link in the email to activate and complete the sign up process.
      </Typography>
    </>

    verificationContentSecondary = <>
      <Typography variant="h2" component="h2">
        No verification email?
      </Typography>

      <Typography component="p">
        If you did not receive the verification email, use the button below to request a new one.
      </Typography>
    </>
  }

  if (user && isVerificationFailed) {
    verificationContentMain = <>
      <Typography component="p">
        Your email address could not be verified.
      </Typography>
      <Typography component="p">
        Please click on the button to send a new email.
      </Typography>
    </>
    verificationContentSecondary = <> </>
  }

  if (user && isVerificationFailed && isLinkExpired) {
    verificationContentMain = <>
      <Typography component="p">
        This link is expired for security reasons.
      </Typography>
      <Typography component="p">
        Please click on the button to send a new email.
      </Typography>
    </>
    verificationContentSecondary = <> </>
  }

  if (user && isVerificationFailed && !verificationContentMain) {
    const reason = message ?? 'Error'
    verificationContentMain = <>
      <Typography component="p">
        {reason}
      </Typography>
    </>
    verificationContentSecondary = <> </>
    setIsButtonVisible(false)
  }

  return (
    <>
      {user &&
        <ApplicationCard header={headerMeta.header} description={headerMeta.description}>

          {verificationContentMain}

          <Divider></Divider>

          {verificationContentSecondary}

          {isButtonVisible &&
            <>
              <div className="buttons-container align-center bb">
                <Button
                  data-testid="verify-email-button"
                  disabled={isButtonDirty}
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={onClickVerify}
                >
                  Resend verification email
                </Button>
              </div>

              <Divider></Divider>
            </>
          }
          <Typography variant="h2" component="h2">
            Need help?
          </Typography>

          <Typography component="p">
            {headerMeta.footer?.text}
            <a
              href={headerMeta?.footer?.linkUrl}
              target="_blank"
              rel="noopener nofollow noreferrer"
            >
              {headerMeta?.footer?.linkText}
            </a>
          </Typography>
        </ApplicationCard>
      }
    </>
  )
}
