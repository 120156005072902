import { useMemo } from 'react'
import { IProfileData, UserType } from '@norstella/nxp-sso-core/interfaces/sso-api'
import { User } from '@norstella/nxp-sso-web/utils/User'
import { useAuth0 } from '@auth0/auth0-react'

export const useProfileData = (): IProfileData => {
  const { user } = useAuth0<User>()

  const getUserType = (user: User) => {
    if (user.ut === UserType.federated) {
      return UserType.federated
    }
    if (user.ut === UserType.trial) {
      return UserType.trial
    }

    if (user.ut === UserType.user) {
      return UserType.user
    }

    throw new Error('The user type is not resolved')
  }

  return useMemo(() => {
    return (
      user ? {
        email: user.email,
        ...user.user_metadata,
        userType: getUserType(user),
      } : {}
    ) as IProfileData
  }, [user])
}
