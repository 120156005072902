import { useSearchParams } from 'react-router-dom'
import { useMemo } from 'react'
import { RegistrationMode } from '@norstella/nxp-sso-core/interfaces/sso-api'
import useLocalStorageState from 'use-local-storage-state'
import { ISignupRedirectState } from '@norstella/nxp-sso-web/hooks/useSignupRedirectState'

const SIGNUP_STATE_KEY = 'nxp-sso:signup_state'

export const useRegistrationMode = (): RegistrationMode => {
  const [searchParams] = useSearchParams()

  const [signupState] = useLocalStorageState<ISignupRedirectState>(
    SIGNUP_STATE_KEY,
  )
  const registrationMode = undefined
    ?? signupState?.data?.registration_mode
    ?? searchParams.get('registration_mode')
    ?? RegistrationMode.REGULAR

  return useMemo(() => registrationMode as RegistrationMode, [registrationMode])
}
