import React, { FC } from 'react'
import { FormType } from '@norstella/nxp-sso-web/utils/Forms'
import { useAppContext } from '@norstella/nxp-sso-web/components/AppContextProvider'
import { ContinueLogin } from '@norstella/nxp-sso-web/components/ContinueLogin'
import { ProfilePageView } from '@norstella/nxp-sso-web/components/views/ProfilePageView'
import { FederatedSignupView } from '@norstella/nxp-sso-web/components/views/FederatedSignupView'

import { useSignupFormType } from '@norstella/nxp-sso-web/hooks/useSignupFormType'
import { TrialUserHeader } from '@norstella/nxp-sso-web/components/TrialUserHeader'
import { EnsureUserRegistrationView } from '@norstella/nxp-sso-web/components/views/EnsureUserRegistrationView'
import { SimpleSignupView } from '@norstella/nxp-sso-web/components/views/SimpleSignupView'

export const Signup: FC = () => {
  const {
    apiHooks,
    profileData,
    shouldContinueLogin,
    registrationStatus,
  } = useAppContext()
  const [completeSignup, , isUpdateInProgress, error] = apiHooks.user.useCompleteSignup()

  const formType = useSignupFormType(
    profileData.userType,
    registrationStatus.registrationMode,
  )

  const views = {
    [FormType.federatedSignup]: <FederatedSignupView
             onSubmitHandler={completeSignup}
             error={error}
          />,
    [FormType.signup]: <ProfilePageView
            formType={formType}
            submitButtonText="Submit"
            isUpdateInProgress={isUpdateInProgress}
            onSubmitHandler={completeSignup}
        />,
    [FormType.trialSignup]: <ProfilePageView
            formType={formType}
            submitButtonText="Submit"
            isUpdateInProgress={isUpdateInProgress}
            onSubmitHandler={completeSignup}
            subHeader={<TrialUserHeader/>}
        />,
    [FormType.simpleSignup]: <SimpleSignupView
        onSubmitHandler={completeSignup}
        error={error}
    />,
    [FormType.ensureUserRegistration]: <EnsureUserRegistrationView
          onSubmitHandler={completeSignup}
          error={error}
      />,
  }

  return !shouldContinueLogin ?
    views[formType] :
    <ContinueLogin/>
}
