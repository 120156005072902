import { useAuth0 } from '@auth0/auth0-react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { IProfileData, RegistrationMode } from '@norstella/nxp-sso-core/interfaces/sso-api'
import { EntitlementType } from '@norstella/nxp-sso-core/interfaces/sso-api/entitlements'
import api from '@norstella/nxp-sso-web/api'
import { CompleteSignupData } from '@norstella/nxp-sso-web/api/userApi'
import { useApiCallback } from '@norstella/nxp-sso-web/hooks/useApiCallback'
import { useSignupRedirectState } from '@norstella/nxp-sso-web/hooks/useSignupRedirectState'
import { useAppContext } from '@norstella/nxp-sso-web/components/AppContextProvider'
import { useFeatureFlags } from '@norstella/nxp-sso-web/hooks/useFeatureFlags'
import { logger } from '@norstella/nxp-sso-web/utils/logger'

export type UseCompleteSignup = [
  callApi: (profileData: IProfileData) => Promise<void>,
  data?: void,
  isLoading?: boolean,
  error?: Error,
]

export const useCompleteSignup = (): UseCompleteSignup => {
  const [callApi, data, isLoading, error] = useApiCallback<CompleteSignupData, void>()
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const [signupState] = useSignupRedirectState()
  const { setShouldContinueLogin } = useAppContext()
  const { isDebugLevelEnabled } = useFeatureFlags()

  const callback = useCallback(async (
    profileData: IProfileData,
    entitlementProviderIds: EntitlementType[] = [],
    registrationMode = RegistrationMode.REGULAR,
  ) => {
    if (isDebugLevelEnabled) {
      logger.debug({ profileData, entitlementProviderIds }, 'useCompleteSignup')
    }

    const result = await callApi(api.userApi.completeSignup(profileData, entitlementProviderIds, registrationMode))
    if (!result || result.error) {
      return
    }

    if (isDebugLevelEnabled) {
      logger.debug({ signupState }, 'useCompleteSignup')
    }

    // if login state is available continue login flow, go to profile otherwise
    if (signupState) {
      setShouldContinueLogin?.(true)
    } else {
      // reload id/access token to fetch profile updates
      await getAccessTokenSilently({ cacheMode: 'off' })
      navigate('/profile', { replace: true })
    }
  }, [callApi, getAccessTokenSilently, isDebugLevelEnabled, navigate, setShouldContinueLogin, signupState])

  return [callback, data, isLoading, error]
}
