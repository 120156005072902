import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export interface IFeatureFlags {
  isDebugLevelEnabled: boolean,
}

const checkDebugMode = (value: string | null): boolean => {
  let isEnabled = !!window?.sessionStorage.getItem('isDebugLevelEnabled')

  if (isEnabled) {
    return true
  }

  if (!value) {
    return false
  }

  isEnabled = false

  if (isEnabled) {
    window.sessionStorage.setItem('isDebugLevelEnabled', 'true')
  }

  return isEnabled
}

export const useFeatureFlags = (): IFeatureFlags => {
  const [searchParams] = useSearchParams()
  const clientId = searchParams.get('client_id')

  return useMemo(() => ({
    isDebugLevelEnabled: checkDebugMode(clientId),
  }), [clientId])
}
