import { Variant } from '@mui/material/styles/createTypography'

export enum FormType {
  federatedSignup = 'federatedSignup',
  signup = 'signup',
  ensureUserRegistration = 'ensureUserRegistration',
  trialSignup = 'trialSignup',
  simpleSignup = 'simpleSignup',
  trialProfile = 'trialProfile',
  profile = 'profile',
  verifyEmail = 'verifyEmail',
  verifyEmailError = 'verifyEmailError',
  continueLogin = 'continueLogin',
  nonBusinessDomain = 'nonBusinessDomain',
  emailCheckFailed = 'emailCheckFailed',
  error = 'error'
}

export interface IHeaderElement {
  text: string,
  image?: string,
  variant?: Variant,
  bgColor?: string,
  isGeneralBrandingUsed?: boolean,
}

export interface IFooterElement {
  text: string,
  linkText?: string,
  linkUrl?: string,
}

export interface IContactsData {
  email?: string,
  phones?: string[],
  link?: {
    url: string,
    text: string,
  },
}

export type Forms = {
  [key in FormType]: {
    header: IHeaderElement,
    description?: IHeaderElement,
    footer?: IFooterElement,
    contacts?: IContactsData,
  }
}

export const forms: Forms = {
  [FormType.profile]: {
    header: {
      image: '/logos/citiline-logo-dual-color.svg',
      text: 'My profile',
    },
  },
  [FormType.signup]: {
    header: {
      image: '/logos/citiline-logo-dual-color.svg',
      text: 'Finish sign up',
    },
    description: {
      text: 'Submit this form to complete sign up',
    },
  },
  [FormType.trialSignup]: {
    header: {
      image: '/logos/citiline-logo-dual-color.svg',
      text: 'Finish sign up',
    },
    description: {
      text: 'Submit this form to complete sign up',
    },
  },
  [FormType.trialProfile]: {
    header: {
      image: '/logos/citiline-logo-dual-color.svg',
      text: 'My profile',
    },
  },
  [FormType.federatedSignup]: {
    header: {
      image: '/logos/citiline-logo-dual-color.svg',
      text: '',
    },
    footer: {
      text: 'If you need further assistance contact our',
      linkText: 'Customer Success',
      linkUrl: 'https://app.evaluate.com/ux/help/contact_us.html',
    },
  },
  [FormType.simpleSignup]: {
    header: {
      image: '/logos/citiline-logo-dual-color.svg',
      text: '',
    },
    footer: {
      text: 'If you need further assistance contact our',
      linkText: 'Customer Success',
      linkUrl: 'https://app.evaluate.com/ux/help/contact_us.html',
    },
  },
  [FormType.ensureUserRegistration]: {
    header: {
      image: '/logos/citiline-logo-dual-color.svg',
      text: '',
    },
    footer: {
      text: 'If you need further assistance contact our',
      linkText: 'Customer Success',
      linkUrl: 'https://app.evaluate.com/ux/help/contact_us.html',
    },
  },
  [FormType.verifyEmail]: {
    header: {
      image: '/logos/citiline-logo-dual-color.svg',
      text: 'Check your email',
    },
    footer: {
      text: 'If you need further assistance contact our',
      linkText: 'Client Services',
      linkUrl: 'https://www.citeline.com/en/contact-us/',
    },
  },
  [FormType.verifyEmailError]: {
    header: {
      image: '/logos/citiline-logo-dual-color.svg',
      text: 'Error',
    },
    footer: {
      text: 'If you need further assistance contact our',
      linkText: 'Client Services',
      linkUrl: 'https://www.citeline.com/en/contact-us/',
    },
  },
  [FormType.nonBusinessDomain]: {
    header: {
      image: '/logos/citiline-logo-dual-color.svg',
      text: 'Non-business email',
    },
    contacts: {
      phones: [
        'Americas: +1 888-670- 8900; +1 908-547- 2200',
        'EMEA: +44 (20) 337 73737',
        'APAC: +61 2 8705 6907',
      ],
      email: 'clientservices@citeline.com',
    },
  },
  [FormType.emailCheckFailed]: {
    header: {
      image: '/logos/citiline-logo-dual-color.svg',
      text: 'Email check',
    },
    contacts: {
      phones: [
        'Americas: +1 888-670- 8900; +1 908-547- 2200',
        'EMEA: +44 (20) 337 73737',
        'APAC: +61 2 8705 6907',
      ],
      email: 'clientservices@citeline.com',
    },
  },
  [FormType.continueLogin]: {
    header: {
      image: '/logos/citiline-logo-dual-color.svg',
      text: '',
    },
  },
  [FormType.error]: {
    header: {
      image: '/logos/citiline-logo-dual-color.svg',
      text: 'Something went wrong',
    },
    footer: {
      text: 'Try again, if the problem persists or you need further assistance contact',
      linkText: 'Customer Support',
      linkUrl: 'https://www.citeline.com/en/contact-us/',
    },
  },
}
