import { useRoutes } from 'react-router-dom'
import { useAvailableRoutes } from '@norstella/nxp-sso-web/hooks/useAvailableRoutes'
import { useAppContext } from '@norstella/nxp-sso-web/components/AppContextProvider'
import { useRedirectStateCleanupEffect } from '@norstella/nxp-sso-web/hooks/useRedirectStateCleanupEffect'
import { logger } from '@norstella/nxp-sso-web/utils/logger'

import './App.module.scss'
import { useFeatureFlags } from '@norstella/nxp-sso-web/hooks/useFeatureFlags'

const App = () => {
  const availableRoutes = useAvailableRoutes()
  useRedirectStateCleanupEffect()

  const routes = useRoutes(availableRoutes)
  const { isLoading } = useAppContext()
  const { isDebugLevelEnabled } = useFeatureFlags()

  if (isDebugLevelEnabled) {
    logger.debug({
      availableRoutes,
      isLoading,
    }, 'App')
  }

  return (
    <div>
      {!isLoading && routes}
    </div>
  )
}

export default App

