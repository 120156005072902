import { Navigate, RouteObject } from 'react-router-dom'
import { AuthenticationGuard } from '@norstella/nxp-sso-web/components/AuthenticationGuard'
import { Callback } from '@norstella/nxp-sso-web/views/Callback'
import { NotFound } from '@norstella/nxp-sso-web/views/NotFound'
import { Profile } from '@norstella/nxp-sso-web/views/Profile'
import { Signup } from '@norstella/nxp-sso-web/views/Signup'
import { VerifyEmail } from '@norstella/nxp-sso-web/views/VerifyEmail'
import { NonBusinessDomain } from '@norstella/nxp-sso-web/views/NonBusinessDomain'
import { EmailCheckFailed } from '@norstella/nxp-sso-web/views/EmailCheckFailed'
import { Error } from '@norstella/nxp-sso-web/views/Error'
import { RegistrationMode } from '@norstella/nxp-sso-core/interfaces/sso-api'
import { VerifyEmailCallback } from '@norstella/nxp-sso-web/views/VerifyEmailCallback'

export interface IRegistrationStatus {
  needEmailVerification: boolean,
  needSignupCompletion: boolean,
  isRegistrationCompleted: boolean,
  registrationMode: RegistrationMode,
}

export enum RouteId {
  signup = 'signup',
  profile = 'profile',
  callback = 'callback',
  verify = 'verify',
  notFound = 'notFound',
  default = 'default',
  nonBusinessDomain = 'nonBusinessDomain',
  emailCheckFailed = 'emailCheckFailed',
  error = 'error',
  verifyEmailCallback = 'verifyEmailCallback'
}

export type ApplicationRoute = RouteObject & { isAvailable: boolean }
export type ApplicationRoutes = { [key in RouteId]: ApplicationRoute }

const verifyEmailRouteIfApplicable = (status: IRegistrationStatus) => status.needEmailVerification && RouteId.verify
const signUpRouteIfApplicable = (status: IRegistrationStatus) => status.needSignupCompletion && RouteId.signup
const profileRouteIfApplicable = (status: IRegistrationStatus) => status.isRegistrationCompleted && RouteId.profile

export const getRouteIdForRegistrationStatus = (registrationStatus: IRegistrationStatus) =>
  verifyEmailRouteIfApplicable(registrationStatus) ||
  signUpRouteIfApplicable(registrationStatus) ||
  profileRouteIfApplicable(registrationStatus) ||
  RouteId.notFound

// refer to https://github.com/auth0/auth0-react/issues/382
const ProtectedProfile = <AuthenticationGuard component={Profile}/>
const ProtectedSignup = <AuthenticationGuard component={Signup}/>
const ProtectedVerifyEmail = <AuthenticationGuard component={VerifyEmail}/>

export const getConfiguredRoutes = (registrationStatus: IRegistrationStatus, search: string) => {
  const { needSignupCompletion, isRegistrationCompleted, needEmailVerification } = registrationStatus
  const allRoutes: ApplicationRoutes = {
    verify: {
      id: RouteId.verify,
      path: `/verify`,
      element: ProtectedVerifyEmail,
      isAvailable: needEmailVerification,
    },
    signup: {
      id: RouteId.signup,
      path: `/signup`,
      element: ProtectedSignup,
      isAvailable: needSignupCompletion,
    },
    profile: {
      id: RouteId.profile,
      path: `/profile`,
      element: ProtectedProfile,
      isAvailable: isRegistrationCompleted,
    },
    callback: {
      id: RouteId.callback,
      path: `/callback${search}`,
      element: <Callback/>,
      isAvailable: true,
    },
    notFound: {
      id: RouteId.notFound,
      path: `/notFound`,
      element: <NotFound/>,
      isAvailable: true,
    },
    error: {
      id: RouteId.error,
      path: `/error`,
      element: <Error/>,
      isAvailable: true,
    },
    default: {
      id: RouteId.default,
      path: `*`,
      isAvailable: true,
    },
    nonBusinessDomain: {
      id: RouteId.nonBusinessDomain,
      path: '/nonBusinessDomain',
      element: <NonBusinessDomain/>,
      isAvailable: true,
    },
    emailCheckFailed: {
      id: RouteId.emailCheckFailed,
      path: '/emailCheck',
      element: <EmailCheckFailed/>,
      isAvailable: true,
    },
    verifyEmailCallback: {
      id: RouteId.verifyEmailCallback,
      path: '/verifyEmailCallback',
      element: <VerifyEmailCallback/>,
      isAvailable: true,
    },
  }

  const defaultRouteId = getRouteIdForRegistrationStatus(registrationStatus)
  const defaultNavigateTo = allRoutes[defaultRouteId].path as string
  allRoutes.default.element = <Navigate to={`${defaultNavigateTo}${search}`} replace/>

  return allRoutes
}
