import { IUserMetadata } from '@norstella/nxp-sso-core/interfaces/auth0'
import { EntitlementType } from './entitlements'

export enum UserType {
  user = 'user',
  federated = 'federated',
  trial = 'trial'
}

export interface IProfileData extends IUserMetadata {
  userType: UserType,
  email: string,
}

export interface IVerifyEmailData {
  appClientId?: string,
}

export enum RegistrationMode {
  REGULAR = 'regular',
  SIMPLE = 'simple',
  ENTITLEMENTS = 'entitlements'
}

export interface ICompleteSignupData {
  first_name: string,
  last_name: string,
  entitlementProviderIds?: EntitlementType[],
  company?: string,
  job_title?: string,
  country_region?: string,
  phone_no?: string | null,
  registration_mode?: RegistrationMode,
}

export interface ICreateFederatedUserData extends ICompleteSignupData {
  fed_user_addl_details: {
    client_user_id: string,
  },
}

export interface IAPIUserAuthRequest {
  email: string,
  password: string,
  scope: string,
  clientId: string,
}

export type IUpdateProfileData = {
  first_name?: string,
  last_name?: string,
  phone_no?: string | null,
  company?: string,
  job_title?: string,
  country_region?: string,
}

export type IUpdateFederatedProfileData = {
  first_name: string,
  last_name: string,
  fed_user_addl_details?: {
    client_user_id: string,
    client_location?: string,
    client_department?: string,
    client_role?: string,
    client_attribute_1?: string,
    client_attribute_2?: string,
  },
}

export type IFedUserParams = {
  client_attribute_1?: string,
  client_attribute_2?: string,
  client_department?: string,
  client_location?: string,
  client_role?: string,
  client_user_id: string,
}
export type IFedUserUpdateParams = {
  first_name?: string,
  last_name?: string,
  fed_user_addl_details: IFedUserParams,
}

export type PartiallyRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>
export type RequiredFieldsOnly<T> = {
  [K in keyof T as T[K] extends Required<T>[K] ? K : never]: T[K]
}
export type PartiallyOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
export type PartialDeep<T> = T extends object ? {
  [P in keyof T]?: PartialDeep<T[P]>;
} : T

export type IResendVerificationEmailRequestData = {
  userId: string,
  appClientId: string,
}

export type ICheckEmailRequestData = {
  email: string,
}

export interface IPasswordChangeRequestData {
  appClientId: string,
}

export interface ISendChangePasswordEmailRequestData {
  applicationGroupId: string,
  email: string,
  connection: string,
}

export interface ISendChangePasswordEmail {
  applicationGroupId: string,
  email: string,
  connection: string,
}

export interface IPasswordChangeParams {
  email: string,
  connectionName: string,
  applicationName?: string,
}

export interface IChangeUserEmailRequest {
  newEmail: string,
}

export interface IChangeUserEmailWithConnection {
  oldEmail: string,
  newEmail: string,
  connection: string,
  applicationName?: string,
}

export interface IPasswordChangeResult {
  redirectUrl: string,
}
