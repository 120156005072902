import { callApi } from '@norstella/nxp-sso-web/api'
import { HttpMethod } from '@norstella/nxp-sso-web/api/HttpMethod'
import {
  ICompleteSignupData,
  IPasswordChangeResult,
  IProfileData,
  IUpdateProfileData,
  IVerifyEmailData,
  RegistrationMode,
} from '@norstella/nxp-sso-core/interfaces/sso-api'

import {
  EntitlementType,
} from '@norstella/nxp-sso-core/interfaces/sso-api/entitlements'

export const endpoints = {
  completeSignup: {
    path: '/signup',
    method: HttpMethod.POST,
  },
  updateProfile: {
    path: '/profile',
    method: HttpMethod.PATCH,
  },
  verifyEmail: {
    path: '/verification-email',
    method: HttpMethod.POST,
  },
  passwordChange: {
    path: '/password-change',
    method: HttpMethod.POST,
  },
}

export class CompleteSignupData implements Partial<ICompleteSignupData> {
  company: string
  country_region: string
  job_title: string
  first_name: string
  last_name: string
  phone_no: string|null
  entitlementProviderIds: EntitlementType[]
  registration_mode: RegistrationMode

  constructor(data: IProfileData, entitlementProviderIds: EntitlementType[] = [], registrationMode = RegistrationMode.REGULAR) {
    this.company = data.company
    this.country_region = data.country_region
    this.job_title = data.job_title
    this.first_name = data.first_name
    this.last_name = data.last_name
    this.phone_no = data.phone_no || null
    this.entitlementProviderIds = entitlementProviderIds
    this.registration_mode = registrationMode
  }
}

export class UpdateProfileData implements Partial<IUpdateProfileData> {
  company: string
  country_region: string
  job_title: string
  first_name?: string
  last_name?: string
  phone_no: string|null

  constructor(data: IProfileData) {
    this.company = data.company
    this.country_region = data.country_region
    this.job_title = data.job_title
    this.first_name = data.isFederated ? undefined : data.first_name
    this.last_name = data.isFederated ? undefined : data.last_name
    this.phone_no = data.phone_no || null
  }
}

const userApiPayloadFactory = <T>(
  Type: { new (data: IProfileData, entitlementProviderIds?: EntitlementType[], registrationMode?: RegistrationMode): T },
  profileData: IProfileData,
  entitlementProviderIds?: EntitlementType[],
  registrationMode?: RegistrationMode,
): T => new Type(profileData, entitlementProviderIds, registrationMode)

const userApi = {
  completeSignup: (profileData: IProfileData, entitlementProviderIds: EntitlementType[] = [], registrationMode = RegistrationMode.REGULAR) => async (token: string) => {
    const data: CompleteSignupData = userApiPayloadFactory(CompleteSignupData, profileData, entitlementProviderIds, registrationMode)
    return await callApi<CompleteSignupData, void>(endpoints.completeSignup, data, token)
  },
  updateProfile: (profileData: IProfileData) => async (token: string) => {
    const data: UpdateProfileData = userApiPayloadFactory(UpdateProfileData, profileData)
    return await callApi<UpdateProfileData, void>(endpoints.updateProfile, data, token)
  },
  verifyEmail: (verifyEmailData: IVerifyEmailData) => async (token: string) => {
    return await callApi<IVerifyEmailData, void>(endpoints.verifyEmail, verifyEmailData, token)
  },
  passwordChange: () => async (token: string) => {
    return await callApi<void, IPasswordChangeResult>(endpoints.passwordChange, undefined, token)
  },
}

export default userApi
