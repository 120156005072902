import { useMemo } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { User } from '@norstella/nxp-sso-web/utils/User'
import { IRegistrationStatus } from '@norstella/nxp-sso-web/services/RoutesService'
import { useSignupRedirectState } from '@norstella/nxp-sso-web/hooks/useSignupRedirectState'
import { useNewEntitlementProviderIds } from '@norstella/nxp-sso-web/hooks/useNewEntitlementProviderIds'
import { useRegistrationMode } from '@norstella/nxp-sso-web/hooks/useRegistrationMode'
import { RegistrationMode } from '@norstella/nxp-sso-core/interfaces/sso-api'

export const useRegistrationStatus = (shouldContinueLogin = false): IRegistrationStatus => {
  const { user, isAuthenticated } = useAuth0<User>()
  const [signupRedirectState] = useSignupRedirectState()
  const newEntitlementProviderIds = useNewEntitlementProviderIds()
  const registrationMode = useRegistrationMode()

  // TODO: refactor to reduce complexity
  // eslint-disable-next-line complexity
  return useMemo(() => {
    const needEmailVerification = isAuthenticated ? !user?.email_verified : true
    const isRegularUser = !user?.user_metadata.isFederated

    const isUpgradingUser = !!(true
    && user?.registration_completed
    && registrationMode === RegistrationMode.REGULAR
    && (false
      || !user?.user_metadata?.first_name
      || !user?.user_metadata?.last_name
      || (isRegularUser && !user?.user_metadata?.job_title)
      || (isRegularUser && !user?.user_metadata?.company)
      || (isRegularUser && !user?.user_metadata?.country_region)
    ))

    const needSignupCompletion = isAuthenticated
      ? (!needEmailVerification && (!user?.registration_completed || newEntitlementProviderIds.length > 0 || isUpgradingUser)) || shouldContinueLogin
      : true

    const isRegistrationCompleted = !signupRedirectState && !needSignupCompletion && !needEmailVerification

    return {
      needEmailVerification,
      needSignupCompletion,
      isRegistrationCompleted,
      registrationMode,
    } as IRegistrationStatus
  },
  [isAuthenticated,
    user?.email_verified,
    user?.user_metadata.isFederated,
    user?.user_metadata?.first_name,
    user?.user_metadata?.last_name,
    user?.user_metadata?.job_title,
    user?.user_metadata?.company,
    user?.user_metadata?.country_region,
    user?.registration_completed,
    registrationMode,
    newEntitlementProviderIds.length,
    shouldContinueLogin,
    signupRedirectState,
  ])
}
