type LoggerFn = (...data: unknown[]) => void

export interface ILogger {
  log: LoggerFn,
  info: LoggerFn,
  warn: LoggerFn,
  error: LoggerFn,
  debug: LoggerFn,
}

export const logger = console as ILogger
