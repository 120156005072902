import { useEffect } from 'react'
import { useSignupRedirectState } from '@norstella/nxp-sso-web/hooks/useSignupRedirectState'
import { useRegistrationStatus } from '@norstella/nxp-sso-web/hooks/useRegistrationStatus'
import { useFeatureFlags } from '@norstella/nxp-sso-web/hooks/useFeatureFlags'
import { logger } from '@norstella/nxp-sso-web/utils/logger'

export const useRedirectStateCleanupEffect = (): void => {
  const { needEmailVerification, needSignupCompletion, isRegistrationCompleted } = useRegistrationStatus()
  const [, cleanupRedirectState] = useSignupRedirectState()
  const { isDebugLevelEnabled } = useFeatureFlags()

  useEffect(() => {
    if (
      !needEmailVerification
      && !needSignupCompletion
      && !isRegistrationCompleted
    ) {
      if (isDebugLevelEnabled) {
        logger.debug({
          needEmailVerification,
          needSignupCompletion,
          isRegistrationCompleted,
        }, 'cleanupRedirectState')
      }

      cleanupRedirectState()
    }
  }, [cleanupRedirectState, isDebugLevelEnabled, isRegistrationCompleted, needEmailVerification, needSignupCompletion])
}
